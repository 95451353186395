import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "./auth.service";
import { CryptoService } from "./crypto.service";

@Injectable()
export class CacheInterceptorService implements HttpInterceptor {
  sessionData: any;

  constructor(
    private jwtHelperSrv: JwtHelperService,
    private authService: AuthService,
    private cryptoService: CryptoService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // let token = localStorage.getItem('sessionKey')

    let isSession =
      req.url.includes("sessions") ||
      req.url.includes("auth") ||
      req.url.includes("token");

    const isGentrix = req.url.includes("gentrix");
    const isExclude = ["user/login"];

    if (isSession) {
      return next.handle(req);
    }

    let isExcluded = isExclude.some((url) => req.url.includes(url));
    const gentrixToken = sessionStorage.getItem("tokenGentrix");
    console.log("gentrixToken", gentrixToken);

    if (isGentrix && !isExcluded) {
      const token = this.cryptoService.decrypt(gentrixToken);

      const httpGentrixRequest = req.clone({
        headers: req.headers
          .set("Cache-Control", "no-cache")
          .set("Pragma", "no-cache")
          .set("Content-Type", "application/json")
          .set("Authorization", "Bearer " + token),
      });

      return next.handle(httpGentrixRequest);
    }

    let token = localStorage.getItem("jwt");

    if (token && this.jwtHelperSrv.isTokenExpired(token)) {
      this.authService
        .tryRefreshingTokens(token)
        .then((refresh) => {
          token = localStorage.getItem("jwt");
          const httpRequest = req.clone({
            headers: req.headers
              .set("Cache-Control", "no-cache")
              .set("Pragma", "no-cache")
              .set("Content-Type", "application/json")
              .set("Authorization", "Bearer " + token),
          });
          return next.handle(httpRequest);
        })
        .catch((error) => console.log("error refresh", error));
    }

    token = localStorage.getItem("jwt");
    const httpRequest = req.clone({
      headers: req.headers
        .set("Cache-Control", "no-cache")
        .set("Pragma", "no-cache")
        .set("Content-Type", "application/json")
        .set("Authorization", "Bearer " + token),
    });

    const fileHttpRequest = req.clone({
      headers: req.headers
        .set("Cache-Control", "no-cache")
        .set("Pragma", "no-cache")
        .set("Authorization", "Bearer " + token),
    });

    if (req.url.includes("file/create")) {
      return next.handle(fileHttpRequest);
    } else {
      return next.handle(httpRequest);
    }
  }
}
