import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()

export class SessionInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private authSrv: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
        
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
            // console.log(event);
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // redirect to the login route
              // or show a modal

              if(err.error.error == "Token mismatch"){
                localStorage.setItem("token-mismatch","true");
                sessionStorage.clear();
                localStorage.clear();
                this.router.navigate(['/pages/login']);
              }



            }
          }
        })
    )
      
  }
}
